function formatDate(date?: string | Date, locale?: string, options?: Intl.DateTimeFormatOptions): string {
  if (!date) {
    return ''
  }

  if (typeof date === "string" && /^\d{6}$/.test(date)) {
    // assume birthdate formatted as ddmmyy
    date = new Date(
      Number(date.substring(4, 6)),
      Number(date.substring(2, 4)) - 1,
      Number(date.substring(0, 2)))
  }

  try {
    return new Intl.DateTimeFormat(locale, options).format(new Date(date))
  } catch (e) {
    console.warn('Failed to format date', e)
    return date?.toString()
  }
}

export default formatDate


/**
 * @param date Date object or date-string.
 * @returns ISO date string (yyyy-mm-dd)
 */
export function toISODate(date?: string | Date): string {
  if (!date) {
    return ''
  }
  try {
    return new Date(date).toISOString().substring(0, 10)
  } catch (e) {
    console.warn('Failed to format date', e)
    return date?.toString()
  }
}